<template>
  <section>
    <VToolbar
      flat
      color="primary"
      dense
      dark
    >
      <VBtn
        icon
        @click="closeUpload"
      >
        <VIcon>close</VIcon>
      </VBtn>
      <VToolbarTitle class="note_subtitle white--text">
        Add Image
      </VToolbarTitle>
      <VSpacer />
      <VToolbarItems>
        <VBtn
          flat
          @click="closeUpload"
        >
          Done
        </VBtn>
      </VToolbarItems>
    </VToolbar>
    <div id="up_bg">
      <div
        v-if="uploadFiles.length < 1"
        id="up_div"
        @click="$refs.selectImgInput.click()"
      >
        <img
          id="up_icon"
          :src="require('../assets/icons/upload.svg')"
          alt="Upload"
          draggable="false"
        >
        <p class="note_subtitle pt-4">
          Click Or Tap To Begin
        </p>
      </div>

      <input
        ref="selectImgInput"
        multiple
        type="file"
        accept="image/x-png,image/jpg,image/jpeg"
        class="d-none"
        @change="onFileSelected"
      >

      <VList
        v-for="(i, index) in uploadFiles"
        id="up_list"
        :key="index"
        two-line
      >
        <VListTile>
          <VListTileAvatar>
            <img :src="i.preview">
          </VListTileAvatar>

          <VListTileContent>
            <VListTileTitle class="note_main_title">
              {{ i.file.name }}
            </VListTileTitle>
            <VProgressLinear
              :value="i.progress"
              color="primary"
              background-color="grey lighten-1"
            />

            <span class="note_main_subtitle">{{ Math.round(i.progress) }} %</span>
          </VListTileContent>

          <VListTileAction>
            <VBtn
              v-if="i.status === 'waiting'"
              icon
              @click="removeImg(index)"
            >
              <VIcon>close</VIcon>
            </VBtn>
            <VProgressCircular
              v-if="i.status === 'loading'"
              size="20"
              width="2"
              indeterminate
              color="primary"
            />

            <VIcon
              v-if="i.status === 'done'"
              color="primary"
            >
              done
            </VIcon>
          </VListTileAction>
        </VListTile>
      </VList>
      <div
        v-if="uploadFiles.length > 0"
        id="up_Btn"
      >
        <VBtn
          color="primary"
          round
          block
          class="shadow"
          :disabled="disableUpload"
          @click="onUpload()"
        >
          Upload All
        </VBtn>
      </div>
    </div>
  </section>
</template>

<script>

import moment from 'moment';
import firebase from '../conf/firebaseAuth';

export default {
  props: ['stdClass'],
  data: () => ({
    selectedFile: [],
    uploadFiles: [],
    disableUpload: false,
    imgUrl: [],
  }),
  methods: {
    onFileSelected(event) {
      this.selectedFile = event.target.files;
      Array.from(this.selectedFile, ((file) => {
        const imgPreview = new FileReader();
        imgPreview.readAsDataURL(file);
        imgPreview.onload = (e) => {
          this.uploadFiles.push({
            file, progress: 0, preview: e.target.result, status: 'waiting',
          });
        };
        return null;
      }));
    },
    removeImg(v) {
      this.uploadFiles.splice(v, 1);
    },
    onUpload() {
      this.disableUpload = true;
      const files = this.uploadFiles;
      files.forEach((el) => {
        const storageRef = firebase.storage().ref(`${moment().format('DD-MM-YYYY')}/${this.stdClass}/${new Date().getTime()
          + Math.random().toString(36).substr(2, 5).toUpperCase()}`);
        const uploadTask = storageRef.put(el.file);
        uploadTask.on('state_changed', (
          (snap) => {
            const progress = snap.bytesTransferred / snap.totalBytes * 100;
            el.progress = progress;
            el.status = 'loading';
          }
        ),
        (() => false),
        (() => {
          uploadTask.snapshot.ref.getDownloadURL()
            .then((url) => {
              this.imgUrl.push(url);
              this.$store.commit('setImgUrl', url);
            });
          el.status = 'done';
        }));
      });
    },
    closeUpload() {
      this.$emit('closeUpload');
    },
  },
};

</script>


<style lang="stylus" scoped>
@import '../assets/stylus/uploadImg';
</style>
