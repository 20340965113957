!<template>
  <section>
    <section class="top">
      <div>
        <p class="note_title">
          {{ noteTitle ? noteTitle: 'Note Title' }}
        </p>
        <p class="note_subtitle">
          Add New Note
        </p>
      </div>
      <div class="note_class">
        <VSelect
          v-model="noteClass"
          :items="selectClass"
          :hide-details="true"
          placeholder="CLASS"
          class="note_subtitle"
          flat
          solo
        />
      </div>
      <div class="top-btn">
        <VBtn
          v-if="noteClass !== ''"
          round
          large
          class="shadow ma-0"
          color="white"
          @click="openImgUpload = true"
        >
          Add Image
        </VBtn>
        <VBtn
          round
          large
          class="shadow"
          color="white"
          @click="save()"
        >
          Save
        </VBtn>
      </div>
    </section>
    <section>
      <VDialog
        v-model="openImgUpload"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <VCard>
          <UploadImg
            :std-class="noteClass"
            @closeUpload="openImgUpload = false"
          />
        </VCard>
      </VDialog>
    </section>
    <section class="content">
      <div class="mt-2">
        <VTextField
          v-model="noteTitle"
          :hide-details="true"
          class="note_main_title"
          placeholder="Title"
          background-color="background"
          solo
          flat
        />
        <VTextarea
          v-model="noteBody"
          placeholder="Type Something..."
          :hide-details="true"
          :auto-grow="true"
          class="note_main_txt"
          background-color="background"
          solo
          flat
          rows="4"
        />
        <div
          v-if="this.$store.state.ImgUrl !== null"
          class="imgContainer"
        >
          <div
            v-for="(img, index) in this.$store.state.ImgUrl"
            :key="index"
          >
            <VImg
              :src="img"
              :lazy-src="require('../../assets/icons/loading.png')"
              alt="Image"
              class="imgContent"
              @click="openImg(img)"
            >
              <template v-slot:placeholder>
                <VLayout
                  fill-height
                  align-center
                  justify-center
                  ma-0
                >
                  <VProgressCircular
                    indeterminate
                    color="grey lighten-3"
                  />
                </VLayout>
              </template>
            </VImg>
          </div>
        </div>
      </div>
    </section>
    <VDialog
      v-model="isSaving"
      width="300"
      persistent
    >
      <VCard
        v-if="loadStatus === 'processing'"
        class="notification_card elevation-20"
      >
        <p>Processing</p>
        <span>Saving Data, Please Wait...</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="primary"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'success'"
        class="notification_card elevation-20"
      >
        <p>Saved</p>
        <span>Note Added Successfully</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="primary"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'savefailed'"
        class="notification_card elevation-20"
      >
        <p>Failed</p>
        <span>Failed To Added Note</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="primary"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'netfail'"
        class="notification_card elevation-20"
      >
        <p>Internet Issue</p>
        <span>Please Try Again Later</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="red"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'fail'"
        class="notification_card elevation-20"
      >
        <p>Error</p>
        <span>An Error Occured</span>
        <div class="notification_ico">
          <VBtn
            depressed
            round
            color="primary"
            @click="reloadPage()"
          >
            Reload
          </VBtn>
        </div>
      </VCard>
    </VDialog>
    <VSnackbar
      v-model="snack"
      color="secondary"
      :timeout="5000"
      :multi-line="true"
    >
      {{ snackTxt }}
      <VBtn
        color="primary"
        depressed
        style="width:100px;"
        round
        @click="snack = false"
      >
        Close
      </VBtn>
    </VSnackbar>
  </section>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import api from '../../conf/api';

import UploadImg from '../../components/uploadImg.vue';

export default {
  components: { UploadImg },
  data: () => ({
    openImgUpload: false, // false in prod
    snack: false,
    snackTxt: '',
    isSaving: false,
    loadStatus: '',
    noteTitle: '',
    noteBody: '',
    noteClass: '',
    selectClass: [],
    date: moment().format('DD-MM-YYYY'),
  }),
  computed: {
    snipIt() {
      const body = this.noteBody;
      if (body.length >= 90) {
        return `${body.substring(0, 100)}...`;
      }
      return body;
    },
  },
  async beforeMount() {
    axios.get(`${api}/settings/classes`).then((res) => {
      this.selectClass = res.data.data;
    });
  },
  methods: {
    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 400);
    },
    save() {
      if (this.noteTitle && this.noteBody && this.noteClass !== '') {
        const noteSchema = {
          title: this.noteTitle,
          body: this.noteBody,
          class: this.noteClass,
          date: this.date,
          snippet: this.snipIt,
          imgUrls: this.$store.state.ImgUrl,
        };
        this.loadStatus = 'processing';
        this.isSaving = true;
        axios.post(`${api}/note/add`, noteSchema)
          .then((res) => {
            if (res.data.status === 'success') {
              this.loadStatus = 'success';
              setTimeout(() => {
                this.isSaving = false;
                this.$store.commit('resetImgUrl');
                this.$router.push('/note');
              }, 2500);
            } else {
              this.loadStatus = 'savefailed';
              setTimeout(() => {
                this.isSaving = false;
              }, 2500);
            }
          }).catch((e) => {
            if (e.code === 'auth/network-request-failed') {
              this.loadStatus = 'netfail';
              setTimeout(() => {
                this.isSaving = false;
              }, 5000);
            } else {
              this.loadStatus = 'fail';
            }
          });
      } else {
        this.snack = true;
        this.snackTxt = 'Please Fill In All The Fields';
      }
    },
    openImg(img) {
      window.open(img);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../../assets/stylus/Note/new';
</style>
